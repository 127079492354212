<template>
  <div class="video-detail">
    <div class="detail-top">
      <div class="top-video">
        <div class="video-inner">
          <van-cell @click="lookVideo"><img :src="videoImg" alt="">
            <div class="icon-center">
              <van-icon name="play-circle-o" />
            </div>
          </van-cell>
          <div class="video-content" @click="onChange">
            <van-popup v-model="showVideo">
              <!-- <van-icon name="cross" @click="showVideo=false" /> -->
              <video ref="url" controls :src="url" />
            </van-popup>
          </div>
        </div>
      </div>

      <div class="top-icon" @click.stop="goBack">
        <van-icon name="arrow-left" />
      </div>
      <div class="top-msg">
        <div class="top-name">{{topVideo.title}}</div>
        <div class="top-box">
          <div class="box-left">
            <span>{{topVideo.playCount}}次播放</span>
            <!--            <span @click="goSubscribe">参与团购-->
            <!--              <van-icon name="arrow" />-->
            <!--            </span>-->
          </div>
          <div class="box-right">
            <div class="icon-item" @click="commentFocus">
              <van-icon name="comment-o" />
            </div>
            <div class="icon-item" @click="collectClick" v-if="!collect">
              <van-icon name="star-o" />
            </div>
            <div class="icon-item" @click="collectClick" v-else>
              <van-icon name="star" color="#E03E3F" />
            </div>
            <div class="icon-item">
              <van-icon name="share-o" @click="share = true" />
            </div>
            <div class="mcover" v-if="share" @click="share = false">
              <img :src="require('../../assets/images/share.png')">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="comment box">
      <div class="box-top">
        <div class="title"> 网友评论<span v-if="commentList && commentList.length > 0 ">({{count }})</span></div>
      </div>
      <!--      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" offset="50">-->
      <div class="comment-box" v-if="commentList && commentList.length > 0">
        <div class="comment-item" v-for="(item,index) in commentList" :key="item.id">
          <div class="comment-img">
            <img :src="item.userAvatar ? item.userAvatar : require('../../assets/images/comment.png')" alt="">
          </div>
          <div class="comment-msg">
            <div class="comment-name">{{item.userName}}</div>
            <div class="comment-content">{{item.content}}</div>
            <div class="comment-bottom">
              <div class="comment-time">{{getTimes.timestampToTime(item.createTime)}}</div>
              <div class="comment-btn">
                <div class="praise-btn" @click="thumbsUp(item.id,item.check, index)">
                  <van-icon name="like-o" v-if="!item.check" />
                  <van-icon color="#E03E3F" name="like" v-else /> 赞 ({{item.likeCount}})
                </div>
                <div class="delete-btn" v-if="item.delBtn" @click="actiondelete(item.id)">删除</div>
              </div>
            </div>
          </div>
        </div>
        <div class="look-more">
          <span v-if="commentList.length<this.count" @click="lookMore">点击查看更多~</span>
          <span v-else-if="commentList.length>=this.count">没有更多了~</span>
        </div>
      </div>
      <div v-else>
        <van-empty image="error" description="暂无更多评论" />
      </div>
      <!--      </van-list>-->
    </div>
    <div class="video-list box">
      <div class="box-top">
        <div class="title">大家都在看</div>
        <div class="more" @click="goVideo">
          更多
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="list-box" v-if="videoList && videoList.length > 0">
        <div class="list-item" v-for="item in videoList" :key="item.id">
          <div class="item-img" @click="getVideo(item.id)">
            <img class="video-img" :src="item.pic" alt="">
            <img class="play-img" src="../../assets/images/play-video.png" alt="">
          </div>
          <div class="list-msg">
            <div class="list-name">{{item.title}}</div>
            <div class="list-num">{{item.playCount}}次播放</div>
          </div>

        </div>
      </div>
      <div v-else>
        <van-empty image="error" description="暂无更多视频" />
      </div>
    </div>
    <div class="detail-bottom">
      <van-field v-model="comment" center clearable ref="comment">
        <template #button>
          <van-button size="small" @click="addContent">发送</van-button>
        </template>
      </van-field>
    </div>
  </div>
</template>
<script>
export default {
  name: "videoDetail",
  data() {
    return {
      pageNum: 1,
      pageSize: 2,
      loading: false,
      finished: false,
      count: "",
      url: "",
      topVideo: {},
      commentList: [],
      videoList: [],
      comment: "",
      more: false,
      collect: false,
      showVideo: false,
      videoImg: require("../../assets/images/swiper01.jpg"),
      share: false,
    };
  },
  created() {
    // 获取传递过来的id
    this.id = this.$route.query.id;
    this.houseid = this.$route.query.houseid;
  },
  methods: {
    onChange() {
      if (this.url && !this.$refs.url.paused) {
        this.$refs.url.pause();
      }
    },
    // onLoad() {
    //   this.loading = true;
    //   this.pageNum++;
    //   this.getHouseVideoDetail()
    // },
    lookVideo() {
      this.showVideo = true;
    },
    getVideo(id) {
      this.pageNum = 1;
      this.pageSize = 2;
      this.getHouseVideoDetail(id, this.houseid);
    },

    goBack() {
      if (sessionStorage.getItem("videoType")) {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: "/buildingDetail",
          query: {
            id: this.houseid,
          },
        });
      }
    },
    goVideo() {
      this.$router.push({
        path: "/buildingVideo",
        query: {
          id: this.houseid,
        },
      });
    },
    //点赞
    thumbsUp(userActionId, check, index) {
      // this.commentList[index].check = !this.commentList[index].check;
      if (this.isLogin()) {
        const data = {
          //评论id
          id: userActionId,
          //是否点赞
          check: check,
        };
        this.$post("houseVideo/commentLike", data).then((res) => {
          if (res.status == 200) {
            this.commentList[index].check = !check;
            if (check) {
              this.commentList[index].likeCount -= 1;
            } else {
              this.commentList[index].likeCount += 1;
            }
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    lookMore() {
      //
      if (this.commentList >= this.count) {
        this.more = true;
      }
      ++this.pageNum;
      const data = {
        //楼盘视频id
        id: this.id,
        houseid: this.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      this.$post("houseVideo/getHouseVideoDetail", data).then((res) => {
        if (res.status == 200) {
          this.loading = false;
          this.finished = false;
          let flag = false;

          this.commentList = this.commentList.concat(res.data.userActions);
        }
      });
    },
    commentFocus() {
      this.$refs.comment.focus();
    },
    collectClick() {
      // this.collect = !this.collect;
      console.log("获取id" + this.id + "zzzz" + this.collect);
      if (this.isLogin()) {
        const data = {
          //资讯id
          id: this.id,
          //是否收藏
          collect: this.collect,
        };
        this.$post("houseVideo/videoCollection", data).then((res) => {
          if (res.status == 200) {
            this.collect = !this.collect;
            this.$toast.success(res.msg);
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },
    goSubscribe() {
      this.$router.push("/OrderHouse");
    },

    getHouseVideoDetail(id, houseid) {
      if (id == undefined && houseid == undefined) {
        const data = {
          //楼盘视频id
          id: this.id,
          houseid: this.houseid,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        this.$post("houseVideo/getHouseVideoDetail", data).then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.finished = false;
            let flag = false;
            this.url = res.data.houseVideo.url;
            this.topVideo = res.data.houseVideo;
            console.log(res.data.houseVideo);
            this.commentList = res.data.userActions;
            this.videoList = res.data.houseVideoList;
            this.count = res.data.count;
            this.collect = res.data.collect;
            this.$getShareInfo(
              this.topVideo.title,
              this.topVideo.pic ||
                Vue.prototype.BASE.API_URL + "/images/logo.png"
            );
          }
        });
      } else {
        const data = {
          //楼盘视频id
          id: id,
          houseid: houseid,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        this.$post("houseVideo/getHouseVideoDetail", data).then((res) => {
          if (res.status == 200) {
            this.loading = false;
            this.finished = false;
            let flag = false;
            this.topVideo = res.data.houseVideo;
            this.url = res.data.houseVideo.url;
            console.log(res.data.houseVideo);
            this.commentList = res.data.userActions;
            this.videoList = res.data.houseVideoList;
            this.count = res.data.count;
            this.collect = res.data.collect;
          }
        });
      }
    },
    //删除评论
    actiondelete(id) {
      if (this.isLogin()) {
        const data = {
          //视频下评论id
          id: id,
        };
        this.$post("houseVideo/delContent", data).then((res) => {
          if (res.status == 200) {
            this.commentList = [];
            this.pageNum = 1;
            this.$toast.success(res.msg);
            // this.goHouseInformationDetail(this.consult.id);
            this.getHouseVideoDetail();
            document.body.scrollTop = document.documentElement.scrollTop =
              document.querySelector(".comment").offsetTop - 300;
          } else {
            this.$toast.fail(res.msg);
          }
        });
      }
    },

    //添加评论
    addContent() {
      if (this.comment == "") {
        this.$toast("请输入评论内容!");
      } else {
        const data = {
          //楼盘视频id
          id: this.id,
          comment: this.comment,
        };
        this.$post("houseVideo/addContent", data).then((res) => {
          if (res.status == 200) {
            this.commentList = [];
            this.pageNum = 1;
            this.comment = "";
            this.getHouseVideoDetail();
            this.$toast.success(res.msg);
            document.body.scrollTop = document.documentElement.scrollTop =
              document.querySelector(".comment").offsetTop - 300;
          }
        });
      }
    },
  },
  mounted() {
    this.getHouseVideoDetail();
  },
  destroyed() {
    sessionStorage.removeItem("videoType");
  },
};
</script>
<style lang="less" scoped>
.video-detail {
  padding-top: 290px;
  padding-bottom: 50px;
  .detail-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 10px solid #f6f6f6;
    z-index: 10;
    .top-video {
      width: 100%;
      height: 210px;
      background-color: #fff;
    }
    .top-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 12px;
      top: 12px;
      .van-icon {
        color: #e5e4e2;
        font-size: 20px;
      }
    }
    .top-msg {
      padding: 12px;
      background: #fff;
      height: 70px;
      box-sizing: border-box;
      div,
      span {
        line-height: 1;
      }
      .top-name {
        color: #333;
        font-size: 16px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .top-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 20px;
        .box-left {
          display: flex;
          align-items: center;
          span {
            font-size: 14px;
            color: #858585;
            margin-right: 20px;
          }
        }
        .box-right {
          display: flex;
          align-items: center;
          .icon-item {
            font-size: 18px;
            margin-left: 20px;
            color: #a2a2a2;
            font-weight: 700;
          }
        }
      }
    }
  }
  .box {
    background-color: #fff;
    padding: 0 12px;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    .box-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 45px;
      .title {
        color: #333;
        font-size: 15px;
        padding-left: 15px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 14px;
          border-radius: 2px;
          background-color: #09d5c6;
        }
      }
      .more {
        color: #858585;
        font-size: 14px;
      }
    }
  }
  .comment {
    .comment-box {
      .comment-item {
        display: flex;
        margin-top: 10px;
        // &:last-child{
        //     .comment-bottom{
        //         border-bottom: 0 !important;
        //     }
        // }
        .comment-img {
          width: 45px;
          height: 45px;
          flex-shrink: 0;
          margin-right: 10px;
          img {
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
        }
        .comment-msg {
          flex: 1;
          .comment-name {
            color: #3b79ad;
            font-size: 16px;
            line-height: 28px;
          }
          .comment-content {
            color: #333;
            font-size: 15px;
            line-height: 24px;
          }
          .comment-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
            .comment-time {
              color: #777;
              font-size: 13px;
            }
            .comment-btn {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #777;
              .praise-btn {
                margin-right: 15px;
              }
            }
          }
        }
      }
      .look-more {
        height: 45px;
        line-height: 45px;
        text-align: center;
        color: #777;
        font-size: 14px;
      }
    }
    /deep/.van-empty {
      padding: 20px 0;
      .van-empty__image {
        width: 100px;
        height: 100px;
      }
    }
  }
  .video-list {
    .list-box {
      .list-item {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        &:last-child {
          border-bottom: 0;
        }
        .item-img {
          width: 131px;
          height: 87px;
          position: relative;
          margin-right: 10px;
          .video-img {
            width: 131px;
            height: 87px;
          }
          .play-img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 35px;
            width: 35px;
          }
        }
        .list-msg {
          flex: 1;
          .list-name {
            color: #333;
            font-size: 18px;
            height: 50px;
            line-height: 25px;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /*! autoprefixer: off */
            -webkit-box-orient: vertical;
            /* autoprefixer: on */
          }
          .list-num {
            font-size: 14px;
            color: #858585;
          }
        }
      }
    }
  }
  .detail-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 12px;
    background-color: #fff;
    display: flex;
    /deep/.van-cell {
      padding: 0;
      .van-field__body {
        input {
          background-color: #f1f1f1;
          border-radius: 15px;
          height: 30px;
          padding: 0 15px;
        }
      }
      .van-field__button {
        .van-button {
          border: 0;
          font-size: 14px;
          padding: 0 15px;

          &::before {
            background-color: #fff;
          }
        }
      }
    }
  }
  /deep/.van-cell {
    padding: 0;
  }
  .video-inner {
    img {
      width: 375px;
      height: 210px;
    }
  }
  .icon-center {
    position: absolute;
    top: 90px;
    right: 150px;
    .van-icon {
      color: #e5e4e2;
      font-size: 60px;
    }
  }
  /deep/.van-overlay {
    background: #000000;
  }
  .video-content /deep/.van-popup {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 210px;
  }
  .video-content {
    .van-icon {
      color: white;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      right: 2px;
    }
    video {
      width: 375px;
      // height: 210px;
    }
  }
}
</style>